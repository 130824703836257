import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from '../../../classNames'
import { Button, DataField, SideMenuParentArrowIcon, SpinnerIcon } from '@sistemiv/s-components'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Combobox } from '@headlessui/react'

const AssigneeSearchMobile: FC<{
  options: TableField[]
  value: TableField[]
  loading?: boolean
  onChange?: (value?: TableField[]) => void
  assignee?: boolean
}> = ({ options, value, loading, onChange, assignee }) => {
  const { t } = useTranslation()
  const tabs = [t('Users'), t('Groups'), t('Org chart')]
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [query, setQuery] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const filteredData =
    query === ''
      ? options.filter((o) => o.tab === selectedTab)
      : options.filter((option) => {
          return option.value.toLowerCase().includes(query.toLowerCase()) && option.tab === selectedTab
        })
  //TODO ovaj kod filtrira po stringu labele posle lokalizacije??? zasto?? treba promeniti i u desktop kodu ovaj feature ne radi na srpskom
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true)
        }}
        className={classNames(
          'flex flex-row items-center text-sm rounded-full border px-2 gap-x-1 text-gray-400 !border-gray-400 font-medium',
          value?.length > 0 ? 'text-white bg-gray-600' : '',
        )}
      >
        <span className='flex gap-x-2 text-sm items-center whitespace-nowrap'>
          {value?.length > 0 ? (
            <DataField field={value[0]} className='overflow-hidden whitespace-nowrap max-w-[8rem] py-0.5' />
          ) : (
            <p className='py-1'>{t('ProcessInstance.filters.ActivityAssignee')}</p>
          )}
          {value?.length > 1 && (
            <div className='rounded-full text-white bg-sky-400 px-1 py-0'>+{value?.length - 1}</div>
          )}
        </span>
        <span className=''>
          {value?.length === 0 ? (
            <SideMenuParentArrowIcon className='w-3 h-3 fill-gray-400 rotate-90' />
          ) : (
            <SideMenuParentArrowIcon className='w-3 h-3 fill-white rotate-90' />
          )}
        </span>
      </button>
      {isOpen && (
        <div className='fixed inset-0 z-50 bg-white p-2 pb-20 pt-6 !mt-0'>
          <div className='flex flex-col w-full p-1'>
            <Combobox
              value={value.map((o) => o.value)}
              onChange={(value) => onChange && onChange(options.filter((f) => value.includes(f.value)))}
              multiple
            >
              <div className='relative'>
                <div className='relative w-full border-b border-gray-300 cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-0 sm:text-sm'>
                  <Combobox.Input
                    className={'w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0'}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={t('search')}
                    name='table-grid-filter-input'
                  />
                  <Combobox.Button
                    onClick={() => setIsOpen(false)}
                    className='absolute inset-y-0 left-0 flex items-center pl-2'
                  >
                    <ArrowLeftIcon className='w-5 h-5 text-gray-400' />
                  </Combobox.Button>
                </div>
                <Combobox.Options
                  className={'relative max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm'}
                  static
                >
                  {assignee && (
                    <div className='flex items-center justify-start gap-x-2 text-sm'>
                      {tabs.map((tab, index) => (
                        <div
                          className={classNames(
                            'whitespace-nowrap py-3 mx-3 text-gray-600 border-b-2 border-gray-300 cursor-pointer',
                            selectedTab === tab ? '!text-sky-500 !border-sky-500' : '',
                          )}
                          key={`tab-${index}`}
                          onClick={() => setSelectedTab(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className='flex justify-between items-center'>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => onChange && onChange(filteredData)}
                      disabled={value?.length === filteredData.length}
                    >
                      {t('ProcessInstance.filters.selectAll')}
                    </Button>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => onChange && onChange([])}
                      disabled={value?.length === 0}
                    >
                      {t('ProcessInstance.filters.clearSelection')}
                    </Button>
                  </div>
                  {loading ? (
                    <div className='flex items-center justify-center w-full'>
                      <SpinnerIcon className='w-4 h-4 text-sky-500' />
                    </div>
                  ) : filteredData.length === 0 && query !== '' ? (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                      {t('ProcessInstance.filters.nothingFound')}
                    </div>
                  ) : (
                    filteredData.map((option, index) => (
                      <Combobox.Option
                        key={`option-${index}`}
                        value={option.value}
                        className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                      >
                        {({ selected }) => (
                          <div className='flex items-center justify-start gap-x-4'>
                            <input
                              type='checkbox'
                              readOnly
                              checked={selected}
                              className={classNames(
                                'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                              )}
                            />
                            <DataField
                              field={option}
                              className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                            />
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </div>
            </Combobox>
          </div>
        </div>
      )}
    </>
  )
}

export default AssigneeSearchMobile
