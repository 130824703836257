import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import ModalDialog from '../../ModalDialog'
import { Button, DatePicker, Option, Select, SpinnerIcon } from '@sistemiv/s-components'
import FormViewer from '../../../extensions/form/FormViewer'
import { useProcessInstanceCreate, useProcessStartFrom } from '../../../repositories'
import { ProcessDefinition, ProcessVersions } from '../../../models/ProcessDefinitionSettings'
import { useProcessVersions } from '../../../repositories/settings/processes/processes/process-versions.repository'
import { useParams } from 'react-router-dom'
import UserService from '../../../services/UserService'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import ProcessSchemasCombobox from '../../user-settings/processes-settings/process-shemas-combobox/ProcessSchemasCombobox'
import { useProcessFlat } from '../../../repositories/settings/processes/processes/process-flat.repository'
import { useTranslation } from 'react-i18next'
import { Priority, getFilteredPriorities } from '../../../utils/priorityUtils'

type CreateProcessInstanceModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectProcess?: boolean
}

export type NodeValue = {
  id: string
  name: string
  path: string
}

const CreateProcessInstanceModal: FC<CreateProcessInstanceModalProps> = ({ open, setOpen, selectProcess }) => {
  const { org, type } = useParams()
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
  const formRef = useRef<any>(null)
  const [selectedVersion, setSelectedVersion] = useState<ProcessVersions | null>(null)
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [notes, setNotes] = useState('')
  const [priority, setPriority] = useState<Priority>({ label: 'Medium', value: 50 })
  const [organizationNodes, setOrganizationNodes] = useState<NodeValue[]>([])
  const [selectedNode, setSelectedNode] = useState<NodeValue>()
  const [selectedProcess, setSelectedProcess] = useState<ProcessDefinition | null>(null)
  const {
    data: startForm,
    isLoading,
    isFetching,
  } = useProcessStartFrom({
    version: selectedVersion?.versionNumber,
    type: selectProcess ? (selectedProcess ? selectedProcess.processDefinitionKey : undefined) : type,
  })
  const {
    data: processVersions,
    isLoading: isVersionsLoading,
    isFetching: isVersionsFetching,
  } = useProcessVersions({
    org,
    type: selectProcess ? (selectedProcess ? selectedProcess.processDefinitionKey : undefined) : type,
  })
  const { data: processSchemas, isLoading: isSchhemasLoading } = useProcessFlat({ org })
  const { mutate: createInstance, isPending } = useProcessInstanceCreate()
  const now = useMemo(() => new Date(), [])
  const { t } = useTranslation()

  useEffect(() => {
    if (!org) return
    UserService.getMyOrgNode(org).then((res) => {
      setSelectedNode(res)
    })
    UserService.listAllOrgNodes(org).then((res) => {
      setOrganizationNodes(res.results)
    })
  }, [org])

  useEffect(() => {
    if (!processVersions) return
    const currentStartable = processVersions?.find((v) => v.current && v.startable) ?? null
    if (currentStartable) {
      setSelectedVersion(currentStartable)
    } else setSelectedVersion(processVersions?.find((v) => v.startable) ?? null)
  }, [processVersions])

  const priorities = getFilteredPriorities(priority.value)

  const handleCreate = (newData: any) => {
    const body = {}
    if (newData) {
      Object.keys(newData).forEach((oldKey) => {
        body[oldKey] = { value: newData[oldKey] }
      })
    }
    createInstance(
      {
        processDefinitionKey: selectProcess ? selectedProcess?.processDefinitionKey ?? '' : type ?? '',
        version: selectedVersion?.versionNumber,
        dueDate: dueDate ? dueDate.toISOString() : '',
        notes: notes,
        priority: priority.value,
        organizationalNode: selectedNode ?? null,
        typedValues: Object.keys(body).length === 0 ? null : body,
      },
      {
        onSuccess: () => {
          setOpen(false)
        },
      },
    )
  }

  const handleChange = (e) => {
    setNotes(e.target.value)
  }

  return (
    <ModalDialog isOpen={open} initialRef={cancelButtonRef}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('CreateProcessModal.newProcess')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {isLoading || isSchhemasLoading || isVersionsLoading || isVersionsFetching ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <>
          {selectProcess && (
            <div className='mt-5 mb-5'>
              <p className='text-xs pb-1 text-gray-600'> {t('CreateProcessModal.process')}</p>
              <ProcessSchemasCombobox
                className='relative w-full cursor-default overflow-x-hidden bg-white text-left border rounded border-gray-400 py-1.5 text-base pl-3 pr-9'
                processSchemas={processSchemas}
                value={selectedProcess ?? undefined}
                onChange={(val) => setSelectedProcess(val)}
              />
            </div>
          )}
          {((selectProcess && selectedProcess) || !selectProcess) && (
            <div className='mt-5 mb-5'>
              <div>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.version')}</p>
                <Select
                  disabled={
                    processVersions?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                      ?.length === 0
                  }
                  className='border-gray-400 !py-1.5 !rounded'
                  value={selectedVersion}
                  displayValue={
                    selectedVersion ? `V.${selectedVersion?.versionNumber} ${selectedVersion?.versionTag}` : ''
                  }
                  onChange={(v) => setSelectedVersion(v)}
                >
                  {processVersions
                    ?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                    .map((pv) => (
                      <Option value={pv} key={pv.id}>
                        V.{pv.versionNumber} {pv.versionTag}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='mt-3'>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.orgNode')}</p>
                <Select
                  disabled={organizationNodes?.length === 0 || (!!selectedNode && organizationNodes?.length === 1)}
                  className='border-gray-400 !py-1.5 !rounded'
                  value={selectedNode}
                  displayValue={selectedNode?.name}
                  onChange={(v) => setSelectedNode(v)}
                >
                  {organizationNodes
                    ?.filter((node) => node?.name !== selectedNode?.name)
                    .map((node) => (
                      <Option value={node} key={node?.id}>
                        {node?.name}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className='mt-3'>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.dueDate')}</p>
                <DatePicker
                  className='border border-gray-400 rounded'
                  value={dueDate}
                  onChange={setDueDate}
                  min={now}
                />
              </div>
              <div className='mt-3'>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.note')}</p>
                <textarea
                  onChange={handleChange}
                  value={notes}
                  style={{ resize: 'none' }}
                  className='text-sm py-2 px-3 border border-gray-400 w-full focus:outline-none focus:ring-2 focus:ring-sky-100 rounded-md'
                />
              </div>
              <div className='mt-3'>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.priority')}</p>
                <Select
                  type='priority'
                  value={priority}
                  onChange={(e) => {
                    setPriority(e)
                  }}
                >
                  {priorities
                    .filter((p) => p.value !== priority.value)
                    .map((p) => (
                      <Option key={p.value} value={p}>
                        <div className='flex justify-start items-center gap-x-3'>
                          <PriorityIcon priority={p.label} />
                          {p.label}
                        </div>
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          )}
          {startForm && startForm?.components?.length > 0 && (
            <FormViewer
              ref={formRef}
              schema={{
                ...startForm,
                components: startForm.components.map((f) => {
                  if (f.type.includes('file')) {
                    return {
                      ...f,
                      organization: org,
                    }
                  }
                  if (f.type.includes('dynamiclist')) {
                    return {
                      ...f,
                      components: f.components.map((component) => {
                        if (component.type.includes('file')) {
                          return {
                            ...component,
                            organization: org,
                          }
                        } else return component
                      }),
                    }
                  }
                  return f
                }),
              }}
              onSubmit={(data) => {
                handleCreate(data)
              }}
            />
          )}
        </>
      )}
      <div className='flex justify-end items-center gap-x-4' data-testid='create-process-buttons'>
        <Button className='text-slate-600' ref={cancelButtonRef} onClick={() => setOpen(false)}>
          {t('Common.cancel')}
        </Button>
        <Button
          className='bg-sky-500 text-white disabled:bg-slate-400'
          loading={isPending}
          tooltip={selectedVersion?.startable === false ? t('CreateProcessModal.notStartableMsg')! : ''}
          disabled={
            isFetching ||
            isPending ||
            isLoading ||
            isVersionsLoading ||
            isVersionsFetching ||
            (selectProcess && !selectedProcess) ||
            selectedVersion?.startable === false
          }
          onClick={() =>
            startForm && startForm?.components?.length > 0 ? formRef?.current?.submitForm() : handleCreate(null)
          }
        >
          {t('Common.create')}
        </Button>
      </div>
    </ModalDialog>
  )
}

export default CreateProcessInstanceModal
