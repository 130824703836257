import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React, { FC, useEffect, useState } from 'react'
import { ProcessTableHeaders, ProcessTableRow } from '../table/TableProcess'
import HeaderSearchSearchMobile from './HeaderSearchSearchMobile'
import ProcessInstanceItemMobile from './ProcessInstanceItemMobile'
import HeaderFiltersMobile from './HeaderFiltersMobile'
import useLocalStorage from 'use-local-storage'
import { useTranslation } from 'react-i18next'
import { ClockIcon, EmptyState, SpinnerIcon } from '@sistemiv/s-components'

interface SearchHistoryItem {
  id: number
  value: string
}

type SearchMobileProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  headers: ProcessTableHeaders[]
  onSearch?(id: string, option: string, value: any): void
  filters: string[]
  headerFilters: any
  tableData: ProcessTableRow[]
  onTableRowClick?: (id: string) => void
  processColor: string | undefined
  isLoading?: boolean
  fetchNextPageMobile?: () => void
}

const ProcessInstancesSearchMobile: FC<SearchMobileProps> = ({
  setOpen,
  headers,
  onSearch,
  filters,
  headerFilters,
  tableData,
  onTableRowClick,
  processColor,
  isLoading,
  fetchNextPageMobile,
}) => {
  const [searchHistory, setSearchHistory] = useLocalStorage<SearchHistoryItem[]>('searchHistory', [])
  const [query, setQuery] = useState('')
  const [showHistory, setShowHistory] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (query?.trim()) {
      const delayTyping = setTimeout(() => {
        setShowHistory(false)
      }, 520)

      return () => clearTimeout(delayTyping)
    } else if (filters && (filters?.length > 1 || filters[0] !== '')) {
      setShowHistory(false)
    } else {
      setShowHistory(true)
    }
  }, [filters, query])

  const handleAddToHistory = () => {
    if (query?.trim()) {
      const newHistoryItem: SearchHistoryItem = {
        id: Date.now(),
        value: query,
      }

      setSearchHistory((prev = []) => {
        const existingItemIndex = prev.findIndex((item) => item.value === query)
        const oldHistory = [...prev]
        if (existingItemIndex >= 0) {
          oldHistory.splice(existingItemIndex, 1)
          oldHistory.unshift(newHistoryItem)
          return oldHistory
        }

        return [newHistoryItem, ...oldHistory]
      })
      setQuery('')
    }
  }
  const handleHistoryClick = (value: string) => {
    setQuery(value)
  }

  //TODO kada pritisnes back button na telefonu ili browseru ugasi ti search modal, moze i da se doda na create new
  // useEffect(() => {
  //   const handlePopState = () => {
  //     closeSearch()
  //   }

  //   window.history.pushState({ modal: true }, '')

  //   window.addEventListener('popstate', handlePopState)

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState)
  //     window.history.back()
  //   }
  // }, [])

  const clearFilters = () => {
    headers.forEach((header) => {
      if (['Activity', 'Priority', 'Resolution', 'ActivityAssignee'].includes(header.id)) {
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          [],
        )
      }
      if (['ActivityAssignee'].includes(header.id)) {
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? header.processInstanceField === 'assignee'
              ? `activities.assigneeId`
              : `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          [],
        )
        onSearch?.('activities.assigneeType', 'in', [])
      }
    })
    onSearch?.('processInstanceCode|name|description', 'like', '')
  }

  const closeSearch = () => {
    clearFilters()
    setOpen(false)
  }

  const handleScroll = (e) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 2
    if (bottom && fetchNextPageMobile) {
      fetchNextPageMobile()
    }
  }
  return (
    <div className='fixed inset-0 z-50 bg-white p-2 pb-20 pt-6 !mt-0'>
      <div className='flex flex-col w-full p-1'>
        <div className='flex flex-row'>
          <button onClick={closeSearch} className='rounded-full hover:bg-sky-50 p-2 flex-shrink-0'>
            <ArrowLeftIcon className='w-6 h-6 text-gray-400' />
          </button>
          <HeaderSearchSearchMobile
            onSearch={onSearch}
            value={headerFilters?.['processInstanceCode|name|description']}
            query={query}
            setQuery={setQuery}
          />
        </div>
        <HeaderFiltersMobile headers={headers} onSearch={onSearch} filters={filters} headerFilters={headerFilters} />
        <div className='font-medium text-sm mt-2 text-gray-600'>
          {showHistory && searchHistory.length > 0 ? t('ProcessInstance.recent') : t('ProcessInstance.results')}
        </div>
      </div>
      <div className='flex flex-col w-full p-1 overflow-y-auto max-h-full pb-24' onScroll={handleScroll}>
        {showHistory && searchHistory.length > 0 ? (
          searchHistory.slice(0, 12).map((historyItem) => (
            <div
              key={historyItem.id}
              onClick={() => handleHistoryClick(historyItem.value)}
              className='flex flex-row gap-x-4 py-1.5 items-center'
            >
              <ClockIcon className='w-6 h-6 fill-gray-400' />
              <div className='text-xl'>{historyItem.value}</div>
            </div>
          ))
        ) : isLoading ? (
          <div className='w-full flex justify-center pt-5'>
            <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
          </div>
        ) : tableData.length > 0 ? (
          tableData.map((row) => (
            <ProcessInstanceItemMobile
              key={row.id.value}
              row={row}
              selectable={false}
              processColor={processColor}
              onRowClick={onTableRowClick}
              handleAddToHistory={handleAddToHistory}
            />
          ))
        ) : (
          <EmptyState emptyText={t('ProcessInstance.noProcesses')} />
        )}
      </div>
    </div>
  )
}

export default ProcessInstancesSearchMobile
