import React, { FC, Fragment } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import AccessCombobox from './AccessCombobox'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { Button, ImgWrapper, SpinnerIcon } from '@sistemiv/s-components'
import { imageBase } from '../../../../services/http-common'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type AccessModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  usersInOrg: any[]
  dataLoading: boolean
  data?: any[]
  item: any
  onAddAccess(value: any, item: any, accessLevel: string | null): void
  onRemoveAccess(value: any, item: any): void
  onChangeUserAccess(value: any, user: any): void
}
const AccessModal: FC<AccessModalProps> = ({
  open,
  setOpen,
  usersInOrg,
  dataLoading,
  data,
  item,
  onAddAccess,
  onRemoveAccess,
  onChangeUserAccess,
}) => {
  const accessLevels = [
    { id: 'My', name: 'My' },
    { id: 'OrgNode', name: 'OrgNode' },
    { id: 'All', name: 'All' },
  ]
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { org } = useParams()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-xl'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('AccessModal.access')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='mt-5 flex flex-col gap-y-3'>
        <div className='grid grid-cols-2 gap-y-3 gap-x-[180px] text-gray-400 text-sm'>
          <p> {t('AccessModal.users')}</p>
          <p> {t('AccessModal.seeInstances')}</p>
        </div>
        {dataLoading ? (
          <div className='w-full flex justify-center'>
            <SpinnerIcon className='w-8 h-8 text-sky-500' />
          </div>
        ) : (
          <div>
            <div className='space-y-2'>
              {data?.slice(0, 3).map((user, index) => (
                <div
                  className='group grid grid-cols-2 gap-y-2 gap-x-[200px]  hover:bg-sky-50 cursor-pointer px-3'
                  key={index}
                >
                  <div className='flex items-center gap-x-2 w-[350px]'>
                    <div className='w-7 h-7 rounded-full overflow-hidden flex items-center justify-center bg-slate-400'>
                      <ImgWrapper
                        src={`${imageBase}/${user.objectId}/76`}
                        className='w-full h-full'
                        onErrorComponent={
                          <p className='text-sm text-white leading-[18px]'>
                            {user ? user.name?.charAt(0) : <UserIcon className='w-full h-full text-white' />}
                          </p>
                        }
                      />
                    </div>
                    <p className='text-sm'>{user.name}</p>
                  </div>
                  <div className='flex justify-end'>
                    <Listbox value={user.accessLevel} onChange={(value) => onChangeUserAccess(value, user)}>
                      <div className='relative w-fit'>
                        <Listbox.Button className='relative w-full px-3 py-2 cursor-default rounded-md pr-10 text-left border border-gray-300'>
                          {user.accessLevel}
                          <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            <ChevronDownIcon className='w-5 h-5 text-gray-700 cursor-pointer' aria-hidden='true' />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute z-10 mt-1 min-w-[12rem] rounded-md max-h-60 w-fit overflow-auto bg-white py-3 drop-shadow-lg shadow-xl ring-1 ring-black ring-opacity-5'>
                            {accessLevels
                              .filter((l) => l.id !== user.accessLevel)
                              .map((level) => (
                                <Listbox.Option
                                  className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                                  key={level.id}
                                  value={level}
                                >
                                  {level.name}
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                    <button
                      onClick={() => {
                        onRemoveAccess(user, item)
                      }}
                      className='ml-auto group-hover:visible invisible'
                    >
                      <XMarkIcon className='w-5 h-5 stroke-gray-500' />
                    </button>
                  </div>
                </div>
              ))}
              {data && data.length > 3 && (
                <Button
                  onClick={() => navigate(`/${org}/settings/processes/${item.id}`)}
                  className='!pl-0  text-blue-500'
                >
                  {t('Settings.seeAll')}
                </Button>
              )}
            </div>
          </div>
        )}
        <AccessCombobox
          topMenu={false}
          onChange={(value) => onAddAccess(value, item, null)}
          options={usersInOrg
            .filter((user) => !data?.some((_user) => _user.id === user.id))
            .map((user) => ({
              type: 'circled_value',
              id: user.id,
              objectId: user.objectId,
              value: user.name,
              icon: `${imageBase}/${user.objectId}/76`,
            }))}
          placeholder={t('AccessModal.addUsers') as string}
          inputPlaceholder={t('Common.search') as string}
        />
      </div>
    </ModalDialog>
  )
}

export default AccessModal
