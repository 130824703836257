import React, { FC, useEffect, useMemo, useState } from 'react'
import CloseButtonIcon from '../../../assets/icons/CloseButtonIcon'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import InfoTabFieldWrapper from './InfoTabFieldWrapper'
import AutoDescription from './AutoDescription'
import { Button, DatePicker, Option, Select } from '@sistemiv/s-components'
import ImgWrapper from '@sistemiv/s-components/dist/esm/components/img-wrapper/ImgWrapper'
import { imageBase } from '../../../services/http-common'
import { useProcessInstanceUpdatePriority } from '../../../repositories/process-instances/mutations/process-instance-set-priority.mutation'
import dayjs from 'dayjs'
import Resolution from './Resolution'
import { useProcessInstanceUpdateDueDate } from '../../../repositories/process-instances/mutations/process-instance-edit-due-date.mutation'
import { useListAllUsersInOrg } from '../../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { Priority, formatPriorityRange, getFilteredPriorities } from '../../../utils/priorityUtils'
import { useParams } from 'react-router-dom'
import { useCurrentProcessDefinition } from '../../../repositories/process-instances/process-definition-selected.repository'
import { useTranslation } from 'react-i18next'
import { calculateTimePassed, calculateTimeRemaining } from '../../../utils/dateUtils'
import { useIsMobile } from '../../../context/ResponsiveContextProvider'
import classNames from '../../../classNames'

type ProcessInstanceInfoTabProps = {
  onClick: () => void
  code: ProcessCode
  createdDate?: string
  resolution?: string
  dueDate?: string
  description?: string
  priority: number
  processInstanceId: string
  createdBy: string | null
  completedAt: string | null
  completed: boolean
}

type ProcessCode = {
  color: string
  name: string
}

const ProcessInstanceInfoTab: FC<ProcessInstanceInfoTabProps> = ({
  onClick,
  code,
  createdDate,
  dueDate,
  description,
  priority,
  processInstanceId,
  resolution,
  createdBy,
  completed,
  completedAt,
}) => {
  const { org, type } = useParams()
  const { mutate: updatePriority } = useProcessInstanceUpdatePriority(processInstanceId)
  const { mutate: updateDueDate } = useProcessInstanceUpdateDueDate(processInstanceId)
  const [priorityValue, setPriorityValue] = useState<Priority>(formatPriorityRange(priority))
  const [dateValue, setDateValue] = useState<Date>(dayjs(dueDate).toDate())
  const { data: users } = useListAllUsersInOrg()
  const { data: currentProcess } = useCurrentProcessDefinition({ org, processDefinitionKey: type })
  const { t } = useTranslation()
  const now = useMemo(() => new Date(), [])
  const isMobile = useIsMobile()

  const timePassed = useMemo(
    () => (completedAt ? calculateTimePassed(createdDate ?? '', completedAt) : calculateTimePassed(createdDate ?? '')),
    [createdDate, completedAt],
  )

  useEffect(() => {
    setDateValue(dayjs(dueDate).toDate())
  }, [dueDate])

  useEffect(() => {
    setPriorityValue(formatPriorityRange(priority))
  }, [priority])

  const timeRemaining = useMemo(
    () => (completedAt ? calculateTimeRemaining(dueDate!, completedAt) : calculateTimeRemaining(dueDate!)),
    [dueDate, completedAt],
  )

  const createdByUser = users?.users?.find((user) => user.objectId === createdBy)

  const priorities = getFilteredPriorities(priorityValue.value)

  return (
    <div
      className={classNames(
        isMobile
          ? 'w-full h-full info-tab-scroll-bar'
          : 'flex w-1/7 min-w-[275px] max-w-[350px]overflow-x-visible items-start justify-between self-stretch pl-[18px] py-0 border-l border-gray-300 overflow-y-auto max-h-[calc(100vh_-_8.30rem)] info-tab-scroll-bar',
      )}
    >
      <div className='flex flex-col py-2 gap-3'>
        <InfoTabFieldWrapper label={t('ProcessInstance.code')}>
          <div className='flex row items-center'>
            <div
              className={`w-4.5 h-4.5 flex rounded  mr-1`}
              style={{ backgroundColor: currentProcess?.color ?? '#000' }}
            ></div>

            <p
              className='text-[color:var(--text-on-main-screen,#26282F)] text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]
'
            >
              {code.name}
            </p>
          </div>
        </InfoTabFieldWrapper>
        {resolution && (
          <InfoTabFieldWrapper label={t('ProcessInstance.resolution')}>
            <Resolution resolution={resolution} />
          </InfoTabFieldWrapper>
        )}
        {/* {dueDate && ( */}
        <InfoTabFieldWrapper label={t('ProcessInstance.instanceDueDate')}>
          {completed ? (
            <p
              className='text-[color:var(--text-on-main-screen,#26282F)] text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]
'
            >
              {dueDate ? dayjs(dateValue).format('DD MMM YYYY') : t('ProcessInstance.unknown')}
            </p>
          ) : (
            <DatePicker
              value={dueDate ? dateValue : null}
              editMode={true}
              inputClassName='!px-0'
              onChange={(date) => {
                setDateValue(date)
                updateDueDate({ date: date })
              }}
              min={now}
            />
          )}
        </InfoTabFieldWrapper>
        {/* )} */}
        {/* <InfoTabFieldWrapper label='Watchers'>
          <CustomCombobox
            watchers={true}
            options={options}
            setSelected={function (assignee: any): void {
              console.log(assignee)
            }}
          />
        </InfoTabFieldWrapper> */}
        <InfoTabFieldWrapper label={t('ProcessInstance.instancePriority')}>
          <Select
            type='priority'
            value={priorityValue}
            onChange={(e) => {
              setPriorityValue(e)
              updatePriority({ num: e.value })
            }}
            editMode={true}
            disabled={completed}
          >
            {priorities
              .filter((p) => p.value !== priorityValue.value)
              .map((p) => (
                <Option key={p.value} value={p}>
                  <div className='flex justify-start items-center gap-x-3'>
                    <PriorityIcon priority={p.label} />
                    {p.label}
                  </div>
                </Option>
              ))}
          </Select>
        </InfoTabFieldWrapper>
        {!description ||
          (description != '' && (
            <InfoTabFieldWrapper label={t('ProcessInstance.autoDesc')}>
              <AutoDescription text={description} />
            </InfoTabFieldWrapper>
          ))}
        <InfoTabFieldWrapper label={`${completed ? t('ProcessInstance.duration') : t('ProcessInstance.timePassed')}`}>
          <p
            className='text-[color:var(--text-on-main-screen,#26282F)] text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]
'
          >
            {timePassed}
          </p>
        </InfoTabFieldWrapper>
        {(dueDate || completedAt) && (
          <InfoTabFieldWrapper
            label={`${
              timeRemaining.startsWith('-') ? t('ProcessInstance.overdue') : t('ProcessInstance.beforeDeadline')
            }`}
          >
            <p
              className={`${
                timeRemaining.startsWith('-') ? 'text-[#E2266A]' : 'text-[color:var(--text-on-main-screen,#26282F)]'
              } text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]`}
            >
              {timeRemaining ? (timeRemaining.startsWith('-') ? timeRemaining.substring(1) : timeRemaining) : '/'}
            </p>
          </InfoTabFieldWrapper>
        )}

        {createdBy && createdByUser && (
          <InfoTabFieldWrapper label={t('ProcessInstance.createdBy')}>
            <div className='flex flex-row'>
              <div className='bg-[#7b87ae] w-7 h-7 rounded-full overflow-hidden text-white flex items-center justify-center text-md'>
                <ImgWrapper
                  className='w-full h-full'
                  src={`${imageBase}/${createdByUser?.objectId}/76`}
                  onErrorComponent={<p>{createdByUser?.name.charAt(0)?.toUpperCase()}</p>}
                />
              </div>
              <p
                className='pl-1  text-[color:var(--text-on-main-screen,#26282F)] text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]
'
              >
                {createdByUser.name}
              </p>
            </div>
          </InfoTabFieldWrapper>
        )}
      </div>
      {!isMobile && (
        <Button onClick={onClick} className=' !p-0 !pt-2 !pr-0 '>
          <CloseButtonIcon className='w-10 h-10 fill-gray-600' />
        </Button>
      )}
    </div>
  )
}

export default ProcessInstanceInfoTab
