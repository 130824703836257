import React, { FC, useMemo, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import AddProcessCombobox from './AddProcessCombobox'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import IconPicker, { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { Base64SVG, ColorSelect, EditInput } from '@sistemiv/s-components'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type EditProcessModalProps = {
  process: any
  value: TableField[]
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onUpdate: (field: string, value: any, modal?: boolean) => void
  icons: IconOption[]
  colors: string[]
  setColors?(value: string[]): void
  onClose?: () => void
}

const EditProcessModal: FC<EditProcessModalProps> = ({
  process,
  value,
  open,
  setOpen,
  onUpdate,
  icons,
  colors,
  setColors,
  onClose,
}) => {
  const { t } = useTranslation()
  const [color, setColor] = useState(process.color)
  const [icon, setIcon] = useState(process.icon)
  const [code, setCode] = useState(process.code)
  const [name, setName] = useState(process.name)
  const [assignee, setAssignee] = useState(process.escalationAssignee)
  const selectedIcon = useMemo(() => icons.find((i) => i.base64string === icon), [icon, icons])
  const selectedAssignee = useMemo(() => value.find((v) => v.objectId === assignee), [assignee, value])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('AddProcessModal.editProcess')}
        <button
          onClick={() => {
            setOpen(false)
            onClose && onClose()
          }}
        >
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5'>
        <div className='space-y-7'>
          <div className='space-y-8'>
            <ColorSelect
              value={color}
              onChange={(value) => {
                onUpdate('color', value, true)
                setColor(value)
              }}
              options={colors}
              onNewColorAdd={(newColor: string) => {
                setColors && setColors([...colors, newColor])
              }}
            />
            <IconPicker
              selected={selectedIcon}
              setSelected={(value) => {
                onUpdate('icon', value, true)
                setIcon(value.base64string)
              }}
              options={icons}
            >
              <Base64SVG
                base64image={icon}
                className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:fill-gray-500 [&>svg>*]:hover:fill-gray-800'
              />
            </IconPicker>
          </div>
          <div className='space-y-6'>
            <EditInput
              value={code}
              onSave={(value) => {
                onUpdate('code', value, true)
                setCode(value)
              }}
            />
            <EditInput
              value={name}
              onSave={(value) => {
                onUpdate('name', value, true)
                setName(value)
              }}
            />
            <AddProcessCombobox
              topMenu
              value={selectedAssignee ?? null}
              onChange={(value) => {
                onUpdate('assignee', value, true)
                setAssignee(value.objectId)
              }}
              options={value}
              placeholder={t('AddProcessModal.selectEscalation') as string}
              inputPlaceholder={t('Common.search') as string}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}

export default EditProcessModal
