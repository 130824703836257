import React, { FC, useEffect, useState } from 'react'
import DashboardSwitch from '../components/dashboard/DashboardSwitch'
import { Layout, SpinnerIcon } from '@sistemiv/s-components'
import TopNav from '../components/layout/TopNav'
import SideNav from '../components/layout/SideNav'
import { useNavigate, useParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { createSignalRContext } from 'react-signalr'
import { getBaseUrl } from '../services/http-common'
import { useQueryClient } from '@tanstack/react-query'
import { useGetApplications } from '../repositories/user/user-applications.repository'
import { useOrganizations } from '../repositories/user/use-organizations.repository'
import UserTasksContextProvider from '../context/UserTasksContextProvider'

export const SignalRContext = createSignalRContext()

export const refreshSideMenuEvents = [
  // 'CategoryItemAddedEvent',
  // 'CategoryItemRemovedEvent',
  // 'ProcessItemAddedEvent',
  // 'ProcessTreeItemPositionChangedEvent',
  // 'ProcessTreeItemIconChangedEvent',
  // 'ProcessItemColorChangedEvent',
  // 'ProcessDefinitionCreatedEvent',
  // 'ProcessTreeItemNameChangedEvent',
  // 'ProcessDefinitionCodeChangedEvent',
  // 'ProcessTreeItemNameChangedEvent',
  'ProcessAccessUserAddedEvent',
  'ProcessAccessUserRemovedEvent',
]

const Dashboard: FC = () => {
  const [isSideOpen, setIsSideOpen] = useState(true)
  const { instance } = useMsal()
  const { org, nav } = useParams()
  const [token, setToken] = useState('')
  const { data: organizations, isLoading: isOrgLoading, isFetching: isOrgFetching } = useOrganizations()
  const { data: applications, isLoading: isAppsLoading, isFetching: isAppsFetching } = useGetApplications()
  const navigate = useNavigate()

  useEffect(() => {
    if (org || !organizations || (organizations.organizations && organizations.organizations.length === 0)) return
    navigate(`/${organizations.organizations.at(0)?.organizationCode}`)
  }, [organizations, org, navigate])

  useEffect(() => {
    const account = instance.getActiveAccount()
    if (account) {
      const accessTokenRequest = {
        scopes: ['fd1442d1-cbcc-4b73-a893-a61b8d3d4782'],
        account: account,
      }
      instance.acquireTokenSilent(accessTokenRequest).then((response) => setToken(response.accessToken))
    }
  }, [instance])

  // useEffect(() => {
  //   const callbackId = instance.addEventCallback((event: EventMessage) => {
  //     if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
  //       const payload = event.payload as any
  //       setToken(payload.accessToken)
  //     }
  //   })

  //   return () => {
  //     if (callbackId) {
  //       instance.removeEventCallback(callbackId)
  //     }
  //   }
  // }, [instance])

  console.log(applications)

  const queryClient = useQueryClient()

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload, data) => {
      console.log(payload)
      console.log(data)
      if (refreshSideMenuEvents.includes(payload) && data.UserId === instance.getActiveAccount()?.localAccountId) {
        queryClient.invalidateQueries({ queryKey: ['side-menu'] })
      }
      if (
        [
          'UserTaskStartedEvent',
          'UserTaskAssigneeChangedEvent',
          'UserTaskEndedEvent',
          'UserTaskInstanceClaimedEvent',
          'UserTaskInstanceClaimedEvent',
        ].includes(payload) &&
        !nav
      ) {
        queryClient.invalidateQueries({ queryKey: ['task-time-series'] })
        queryClient.invalidateQueries({
          queryKey: ['tasks-paginated', org, 6],
          refetchType: 'all',
        })
      }
    },
    [],
  )

  return isOrgLoading || isOrgFetching || isAppsLoading || isAppsFetching ? (
    // return isOrgLoading || isOrgFetching ? (
    <div className='w-full flex items-center justify-center h-screen'>
      <SpinnerIcon className='text-sky-500 h-10 w-10'></SpinnerIcon>
    </div>
  ) : (
    <SignalRContext.Provider
      connectEnabled={
        !!token
        // &&
        // !!applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'manage')
      }
      accessTokenFactory={() => {
        console.log('factory token')
        const account = instance.getActiveAccount()
        if (account) {
          const accessTokenRequest = {
            scopes: ['fd1442d1-cbcc-4b73-a893-a61b8d3d4782'],
            account: account,
          }
          const newToken = instance.acquireTokenSilent(accessTokenRequest).then((response) => response.accessToken)
          console.log(newToken)
          return newToken
        }
        return token
      }}
      dependencies={[token]} //remove previous connection and create a new connection if changed
      url={`https://${getBaseUrl()}/s-notifications/api/${org}/real-time-updates`}
    >
      {isAppsLoading || isAppsFetching ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (
        <Layout
          topNavComponent={<TopNav setSideOpen={setIsSideOpen} />}
          sideMenuComponent={<SideNav isSideOpen={isSideOpen} />}
        >
          <UserTasksContextProvider>
            <DashboardSwitch />
          </UserTasksContextProvider>
        </Layout>
      )}
    </SignalRContext.Provider>
  )
}

export default Dashboard
