import React, { FC, Fragment, useState } from 'react'
import classNames from '../../../classNames'
import { useFilters } from '../../../repositories'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { SideMenuParentArrowIcon } from '@sistemiv/s-components'

type CustomFiltersMobileProps = {
  toggleModal: (modalName: string, open: boolean) => void
}

const CustomFiltersMobile: FC<CustomFiltersMobileProps> = ({ toggleModal }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeFilterName, setActiveFilterName] = useState('')
  const { org, type } = useParams()
  const { data: filters } = useFilters({ organization: org, key: type })
  const { t } = useTranslation()

  const handleClickOnFilter = ({ id, name }: { id: string; name: string }) => {
    const activeFilter = searchParams.get('activeFilter')
    if (activeFilter !== id) {
      setSearchParams({ activeFilter: id })
      setActiveFilterName(name)
    } else {
      clearFilters()
    }
  }

  const clearFilters = () => {
    setSearchParams()
    setActiveFilterName('')
  }
  return (
    <div className='w-full items-center flex gap-x-3 overflow-x-auto no-scrollbar'>
      <Menu as='div' className='relative'>
        {({ open }) => {
          toggleModal('CustomFiltersMobile', open)
          return (
            <>
              <Menu.Button
                name='custom-filters'
                className={classNames(
                  activeFilterName
                    ? 'flex flex-row gap-x-1 items-center text-sm rounded-full border bg-blue-100 !border-blue-300 px-2 py-1'
                    : ' text-blue-500',
                )}
              >
                {activeFilterName ? (
                  <>
                    <span className='whitespace-nowrap'>{activeFilterName} </span>
                    <SideMenuParentArrowIcon className='w-3 h-3 fill-black rotate-90' />
                  </>
                ) : (
                  <span className='text-sm'>{t('ProcessInstance.filters.applyCustomFilter')}</span>
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity duration-80'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-80'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black opacity-40 z-30' onClick={() => close()} />
              </Transition>
              <Transition
                as={Fragment}
                enter='transition-transform duration-80 ease-out'
                enterFrom='transform translate-y-full'
                enterTo='transform translate-y-0'
                leave='transition-transform duration-80 ease-in'
                leaveFrom='transform translate-y-0'
                leaveTo='transform translate-y-full'
              >
                <Menu.Items
                  className='fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg py-4 px-3 gap-y-1 z-[31] max-h-[50vh]'
                  data-testid='custom-filter-wrapper'
                >
                  <div className='w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                  <div className='flex flex-col w-full'>
                    <div className='flex flex-row pb-2 items-center justify-between'>
                      <span className='text-xs font-medium'>{t('ProcessInstance.filters.customFilter')}</span>
                      <span
                        className={`text-blue-500 text-sm cursor-pointer ${
                          !searchParams.has('activeFilter') ? 'text-gray-400' : ''
                        }`}
                        onClick={clearFilters} // Clear filter on click
                      >
                        {t('ProcessInstance.filters.clearFilter')}
                      </span>
                    </div>
                    <div className='w-full max-h-[calc(50vh-50px)] overflow-y-auto overflow-x-hidden pb-6'>
                      <div className='border-b border-b-gray-200'></div>
                      {filters?.map((filter) => (
                        <Menu.Item key={filter.id}>
                          <div
                            onClick={() => handleClickOnFilter({ id: filter.id, name: filter.name })}
                            className='pl-2'
                            style={{ backgroundColor: filter.color }}
                          >
                            <div
                              className={classNames(
                                'flex flex-row items-center justify-between p-1.5 cursor-pointer border-b border-b-gray-200 hover:bg-sky-50',
                                searchParams.get('activeFilter') === filter.id ? 'bg-gray-100' : 'bg-white',
                              )}
                            >
                              <span
                                data-testid='custom-filter-name'
                                className='whitespace-nowrap overflow-hidden text-ellipsis'
                                style={{ color: filter.color }}
                              >
                                {filter.name}
                              </span>
                              <span></span>
                            </div>
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )
        }}
      </Menu>
      {searchParams.has('activeFilter') && (
        <span className='text-sm cursor-pointer text-blue-500 whitespace-nowrap' onClick={clearFilters}>
          {t('ProcessInstance.filters.clearCustomFilter')}
        </span>
      )}
    </div>
  )
}

export default CustomFiltersMobile
