import { Listbox } from '@headlessui/react'
import {
  MinusIcon,
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import { SpinnerIcon, DataField, Button, RestartIcon, CheckboxMark } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { FC, useEffect, useRef, useState } from 'react'
import HeaderSearchField from './HeaderSearchField'
import { useTranslation } from 'react-i18next'
import { useIsMobile, useNavigationContext } from '../../../context/ResponsiveContextProvider'
import InstanceSorterMobile from '../mobile-view/InstanceSorterMobile'
import ProcessInstanceItemMobile from '../mobile-view/ProcessInstanceItemMobile'
import ProcessInstancesSearchMobile from '../mobile-view/ProcessInstancesSearchMobile'

export type ProcessTableHeaders = {
  id: string
  name: string
  itemType: string
  position: number
  key?: string
  options?: string[]
  searchValue?: any
  searchType?: string
  [key: string]: any
}

export type ProcessTableField = {
  type:
    | 'id'
    | 'value'
    | 'box'
    | 'solid'
    | 'date'
    | 'border'
    | 'icon'
    | 'circled_icon'
    | 'circled_value'
    | 'image'
    | 'image_value'
    | 'priority'
    | 'priority_select'
    | 'b64_value'
  subType: string
  value: any
  icon?: any
  image?: string
  borderColor?: string
  solidColor?: string
  boxColor?: string
  [key: string]: any
}

export type ProcessTableRow = {
  [key: string]: ProcessTableField
}

type TableProcessProps = {
  headers: ProcessTableHeaders[]
  rows: ProcessTableRow[]
  selectedRows: ProcessTableRow[]
  onTableRowClick?: (id: string) => void
  setSelectedRows: DispatchSetAction<ProcessTableRow[]>
  onRestartFailedTask?: (id: string) => Promise<void>
  onSearch?: (field: string, operation: string, value: any) => void
  filters: string[]
  headerFilters: any
  restartedTasks: string[]
  setRestartedTasks: any
  processColor?: string
  isMobileSearchOpen?: boolean
  setIsMobileSearchOpen?: DispatchSetAction<boolean>
  isLoading?: boolean
  fetchNextPageMobile?: () => void
  scrollableMobileComponent?: React.RefObject<HTMLDivElement>
}

const TableProcess: FC<TableProcessProps> = ({
  headers,
  rows,
  onTableRowClick,
  selectedRows,
  filters,
  headerFilters,
  setSelectedRows,
  onRestartFailedTask,
  onSearch,
  restartedTasks,
  setRestartedTasks,
  processColor,
  isMobileSearchOpen,
  setIsMobileSearchOpen,
  isLoading,
  fetchNextPageMobile,
  scrollableMobileComponent,
}) => {
  const isMobile = useIsMobile()
  const { hideNavigations } = useNavigationContext()
  const [tableData, setTableData] = useState<ProcessTableRow[]>([])
  const [bulkSelectValue, setBulkSelectValue] = useState('')
  const [sortField, setSortField] = useState('')
  const [order, setOrder] = useState('asc')
  const bulkSelectOptions = ['Visible', 'None']
  const [restartingTaskId, setRestartingTaskId] = useState('')
  const [rowExpanded, setRowExpanded] = useState<number | string>('')
  const [expandedRows, setExpandedRows] = useState([])
  const { t } = useTranslation()
  // const timeoutRef = useRef<NodeJS.Timeout | null>(null) vezano sa delayed handle scroll
  const lastScrollY = useRef(0)
  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      const sorted = [...rows].sort((a, b) => {
        if (
          (a[sortField] === null || a[sortField] === undefined) &&
          (b[sortField] === null || b[sortField] === undefined)
        )
          return 0
        if (a[sortField] === null || a[sortField] === undefined) return 1
        if (b[sortField] === null || b[sortField] === undefined) return -1
        if (a[sortField]?.type === 'date' && b[sortField]?.type === 'date') {
          return (dayjs(a[sortField].value).isBefore(b[sortField].value) ? -1 : 1) * (sortOrder === 'asc' ? 1 : -1)
        }
        return (
          a[sortField].value.toString().localeCompare(b[sortField].value.toString(), 'en', { numeric: true }) *
          (sortOrder === 'asc' ? 1 : -1)
        )
      })
      setTableData(sorted)
    }
  }

  const handleSortingChange = (accessor: string) => {
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc'
    setSortField(accessor)
    setOrder(sortOrder)
    handleSorting(accessor, sortOrder)
  }

  useEffect(() => {
    if (!rows || rows.length === 0) {
      setTableData([])
      return
    }
    if (sortField) {
      handleSorting(sortField, order)
    } else {
      setTableData([...rows])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, rows, sortField])

  useEffect(() => {
    setRestartingTaskId('')
    setRestartedTasks([])
    setRowExpanded('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  const allRowsSelected =
    selectedRows?.length > 0 &&
    rows?.every((row) => selectedRows?.find((selected) => selected['id']?.value === row?.['id'].value))

  const handleScroll = (e) => {
    //ovaj kod nije los ako zelimo da dodamo delay za skrolovanje da se animacija ne aktivira odmah jer sta ako korisnik krena da zeza aplikaciju i da skrola brzo gore dole
    // const currentScrollY = e.target.scrollTop
    // if (timeoutRef.current) {
    //   clearTimeout(timeoutRef.current)
    // }

    // timeoutRef.current = setTimeout(() => {
    //   if (currentScrollY > lastScrollY && currentScrollY > 100) {
    //     hideNavigations && hideNavigations(true)
    //   } else if (currentScrollY < lastScrollY) {
    //     hideNavigations && hideNavigations(false)
    //   }

    //   setLastScrollY(currentScrollY)
    // }, 200)

    const currentScrollY = e.target.scrollTop

    if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
      hideNavigations && hideNavigations(true)
    } else if (currentScrollY < lastScrollY.current) {
      hideNavigations && hideNavigations(false)
    }

    lastScrollY.current = currentScrollY

    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 2
    if (bottom && fetchNextPageMobile) {
      fetchNextPageMobile()
    }
  }

  const desktopContent = (
    <table className='min-w-full divide-y divide-gray-200 border-collapse'>
      <thead className='text-sm bg-white'>
        <tr className='border-b border-gray-300'>
          <th className='px-3'>
            <div className='relative flex items-center gap-x-1'>
              <div>
                <span
                  className={classNames(
                    selectedRows.length > 0 ? 'border-sky-500' : 'border-gray-300',
                    allRowsSelected ? 'bg-sky-500' : 'bg-white',
                    'w-4.5 h-4.5 border-2 rounded-sm inline-flex items-center justify-center cursor-pointer',
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    if (selectedRows.length === 0) {
                      // setSelectedRows(rows.map((row) => row['id'].value))
                      setSelectedRows(rows)
                    } else {
                      setSelectedRows([])
                    }
                  }}
                >
                  {allRowsSelected && <CheckboxMark className='w-4.5 h-4.5 fill-white flex-none' />}
                  {selectedRows.length > 0 && !allRowsSelected && (
                    <MinusIcon className='text-sky-500 w-4 h-4 flex-none stroke-2' />
                  )}
                </span>
              </div>

              <Listbox
                value={bulkSelectValue}
                onChange={(value) => {
                  if (value === 'None') {
                    setSelectedRows([])
                  } else {
                    // setSelectedRows(rows.map((row) => row['id']?.value))
                    setSelectedRows(rows)
                  }
                  setBulkSelectValue(value)
                }}
              >
                <div className='relative text-left font-normal'>
                  <Listbox.Button className='relative inset-0 inline-flex items-center justify-center'>
                    <ChevronDownIcon className='w-4 h-4 text-gray-600 !stroke-2' />
                  </Listbox.Button>
                  <Listbox.Options
                    className={
                      'absolute z-10 mt-1 max-w-xs rounded-md min-w-max max-h-60 w-full overflow-auto py-3 drop-shadow-md shadow-md bg-white'
                    }
                  >
                    {bulkSelectOptions.map((option, index) => (
                      <Listbox.Option
                        key={`option-${index}`}
                        value={option}
                        className={`px-4 py-2 bg-white hover:bg-blue-50 cursor-pointer`}
                      >
                        {option}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>
          </th>
          {headers.map((header, index) => (
            <th
              data-testid='table-header'
              key={index}
              scope='col'
              className='px-6 py-3 text-left font-medium text-slate-600 capitalize tracking-wider cursor-pointer'
              onClick={() => handleSortingChange(header.id)}
            >
              <div className='inline-flex items-center justify-start gap-x-3 '>
                {header.name}
                {order === 'asc' ? (
                  <ArrowDownIcon
                    className={classNames(
                      'h-4 w-4',

                      sortField === header.id ? 'text-slate-500' : 'text-transparent',
                    )}
                  />
                ) : (
                  <ArrowUpIcon
                    className={classNames(
                      'h-4 w-4',

                      sortField === header.id ? 'text-slate-500' : 'text-transparent',
                    )}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
        <tr className='border-b border-gray-300'>
          <th></th>
          {headers.map((header, index) => (
            <th
              key={index}
              scope='col'
              className='px-6 py-3 text-left !font-normal !text-gray-500 min-w-[12rem] tracking-wider'
              data-testid='filter'
            >
              <HeaderSearchField
                header={header}
                value={
                  headerFilters?.[
                    header.processInstanceField.toLowerCase() === 'activities'
                      ? 'activities.activityId'
                      : header.scope === 'Activity'
                      ? header.processInstanceField === 'assignee'
                        ? `activities.assigneeId`
                        : `activities.${header.processInstanceField}`
                      : header.scope === 'Variable'
                      ? `typedValues.${header.processInstanceField}`
                      : header.processInstanceField
                  ]
                }
                onSearch={onSearch}
                filters={filters}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='bg-white'>
        {tableData &&
          tableData.length > 0 &&
          tableData.map((row, index) =>
            restartingTaskId == row.id.value ? (
              <tr key={index} data-testid='row' className='text-white'>
                <td className='rounded-sm bg-[#6275A5] text-sm' colSpan={headers.length + 1}>
                  <div className='flex items-center px-3 py-2 gap-x-10'>
                    <div className='w-fit flex-none'>
                      <SpinnerIcon className='w-6 h-6 text-blue-500' />
                    </div>
                    <div className='flex items-center gap-x-2'>
                      <DataField
                        field={row.ProcessInstanceCode}
                        className='max-w-[15rem] overflow-hidden text-ellipsis whitespace-nowrap'
                        copyBtn={true}
                      />
                      <div>{t('ProcessInstance.restartFailed')}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <tr
                key={index}
                className={classNames(
                  'border-b border-gray-300',
                  selectedRows.includes(row['id'].value) ? 'bg-blue-50' : '',
                )}
                data-testid='row'
              >
                <td className='px-3'>
                  <BulkCheckbox selectedRows={selectedRows} setSelectedRows={setSelectedRows} row={row} />
                </td>
                {headers.map((header, cellIndex) => {
                  const field = row[header.id]
                  return (
                    <td
                      key={cellIndex}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      {header.id === 'ProcessInstanceCode' ? (
                        <div className={`flex items-center gap-x-2 ${!!row.incidents.value.length && 'text-pink-600'}`}>
                          <p
                            className='max-w-[15rem] overflow-hidden text-ellipsis leading-5 text-sky-500 cursor-pointer'
                            onClick={() => onTableRowClick?.(row.id?.value)}
                          >
                            {row.ProcessInstanceCode.value}
                          </p>
                          {!!row.incidents.value.length && !restartedTasks.includes(row.id.value) && (
                            <>
                              <Button
                                className='!p-0'
                                tooltip={
                                  row.incidents?.value[0].message +
                                  (row.incidents.value?.length > 1 ? ', and more...' : '')
                                }
                              >
                                <ExclamationCircleIcon className='w-5 h-5' />{' '}
                              </Button>
                              {row.incidents?.value[0].retriable && (
                                <Button
                                  className='!p-0'
                                  tooltip='Retry'
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    setRestartingTaskId(row.id.value)
                                    for (const element of row.incidents.value) {
                                      await onRestartFailedTask?.(element.activityInstanceId)
                                    }
                                    setRestartingTaskId('')
                                    setRestartedTasks((old) => [...old, row.id.value])
                                  }}
                                >
                                  <RestartIcon className='w-3.5 h-3.5 text-gray-400 hover:text-gray-700' />
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      ) : field && field.value && Array.isArray(field.value) ? (
                        field.subType && field.subType === 'activity' ? (
                          <div className='space-y-2'>
                            <DataField
                              field={{
                                ...field,
                                value: field.value[0],
                                icon: Array.isArray(field.icon) ? field.icon[0] : field.icon,
                              }}
                              expandFor={rowExpanded === index ? 'collapse' : field.value.length - 1}
                              expandClicked={() => {
                                const data = JSON.parse(JSON.stringify(field.value))
                                if (rowExpanded === index) setRowExpanded('')
                                else setRowExpanded(index)
                                data.shift()
                                setExpandedRows(data)
                              }}
                              key={`option-${index}`}
                              className='max-w-[15rem] overflow-hidden text-ellipsis'
                            />
                            {Number.isInteger(rowExpanded) &&
                              rowExpanded === index &&
                              expandedRows.map((option, i) => (
                                <DataField
                                  field={{
                                    ...field,
                                    value: option,
                                    icon: Array.isArray(field.icon) ? field.icon[i] : field.icon,
                                  }}
                                  key={`option-expand-${i}`}
                                  className='max-w-[15rem] overflow-hidden text-ellipsis'
                                />
                              ))}
                          </div>
                        ) : (
                          <div className='space-y-2'>
                            {rowExpanded === index ? (
                              field.value.map((option, fieldIndex) => (
                                <DataField
                                  field={{
                                    ...field,
                                    value: option,
                                    icon: Array.isArray(field.icon) ? field.icon[index] : field.icon,
                                  }}
                                  key={`option-${fieldIndex}`}
                                  className='max-w-[15rem] overflow-hidden text-ellipsis'
                                />
                              ))
                            ) : (
                              <DataField
                                field={{
                                  ...field,
                                  value: field.value[0],
                                  icon: Array.isArray(field.icon) ? field.icon[0] : field.icon,
                                }}
                                key={`option-${index}`}
                                className='max-w-[15rem] overflow-hidden text-ellipsis'
                              />
                            )}
                          </div>
                        )
                      ) : (
                        <DataField
                          field={field}
                          className='max-w-[15rem] overflow-hidden text-ellipsis'
                          copyBtn={true}
                          link={header.id === 'Code'}
                          onLinkClicked={() => onTableRowClick?.(row.id?.value)}
                        />
                      )}
                    </td>
                  )
                })}
              </tr>
            ),
          )}
      </tbody>
    </table>
  )
  const mobileContent = (
    <div className='space-y-4 h-full overflow-y-auto pb-64' onScroll={handleScroll} ref={scrollableMobileComponent}>
      <div className='flex items-center space-x-2'>
        {tableData.length !== 0 && (
          <InstanceSorterMobile
            sortField={sortField}
            setSortField={setSortField}
            options={headers}
            order={order as 'asc' | 'desc'}
            setOrder={setOrder}
          />
        )}
      </div>
      <div className={isLoading ? 'opacity-50 pointer-events-none select-none' : ''}>
        {tableData.map((row) => (
          <ProcessInstanceItemMobile
            key={row.id.value}
            row={row}
            selectable={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            processColor={processColor}
            onRowClick={onTableRowClick}
          />
        ))}
      </div>
      {isMobileSearchOpen && (
        <ProcessInstancesSearchMobile
          setOpen={setIsMobileSearchOpen!}
          headers={headers}
          onSearch={onSearch}
          filters={filters}
          headerFilters={headerFilters}
          tableData={tableData}
          onTableRowClick={onTableRowClick}
          processColor={processColor}
          isLoading={isLoading}
          fetchNextPageMobile={fetchNextPageMobile}
        />
      )}
    </div>
  )

  if (isMobile) return mobileContent

  return desktopContent
}

const BulkCheckbox: FC<{
  // selectedRows: string[]
  // setSelectedRows: DispatchSetAction<string[]>
  selectedRows: ProcessTableRow[]
  setSelectedRows: DispatchSetAction<ProcessTableRow[]>
  row: ProcessTableRow
}> = ({ selectedRows, setSelectedRows, row }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div
      className='p-3 rounded-full hover:bg-blue-100 flex justify-center items-center transition-all ease-out duration-75'
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        if (inputRef.current) {
          inputRef.current.click()
        }
      }}
    >
      <input
        ref={inputRef}
        type='checkbox'
        // checked={selectedRows.includes(row['id'].value)}
        checked={!!selectedRows.find((selectedRow) => selectedRow['id'].value === row['id'].value)}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          if (e.target.checked) {
            // setSelectedRows((oldRows) => {
            //   return [...oldRows, row['id'].value]
            // })
            setSelectedRows((oldRows) => {
              return [...oldRows, row]
            })
          } else {
            // setSelectedRows((oldRows) => {
            //   return [...oldRows].filter((r) => r !== row['id'].value)
            // })
            setSelectedRows((oldRows) => {
              return [...oldRows].filter((r) => r['id'].value !== row['id'].value)
            })
          }
        }}
        className={classNames(
          'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default  disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
        )}
      />
    </div>
  )
}

export default TableProcess
