import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useProcessIntanceSubprocesses = (data: string[]) => {
  const { org, type, id } = useParams()
  return useQuery<any>({
    queryKey: ['process-instance-subprocesses', org, type, data, id],
    queryFn: async () => {
      const response = await Promise.all(
        data.map((item) =>
          ProcessInstancesService.list(org!, item, 1, 1000, { filter: [`parentProcessInstanceId:eql=${id}`] }),
        ),
      )
      const subprocessData: any[] = []
      response.forEach((resPage) => {
        resPage.results.forEach((instance) => {
          if (instance.parentProcessInstanceId === id) subprocessData.push(instance)
        })
      })
      return subprocessData
    },
    enabled: !!org && !!type && !!data && !!id,
    staleTime: 0,
  })
}
