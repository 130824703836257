export interface ProcessInstance {
  id: string
  _etag: string
  processDefinitionKey: string
  ProcessDefinitionId: string
  parentCodeId?: string
  executionId: string
  objectId: string
  emails: string[]
  nodeId: string
  nodeCode: string
  nodePath: string
  processInstanceCode?: string
  code?: string
  version: string
  name: string
  description: string
  activities: ProcessInstanceActivity[]
  parentCode: string
  resolution: string
  priority: number
  dueDate: Date
  owner: string
  watchers?: string[]
  incidents?: string
  deleted: boolean
  created: Date
  userId: string
  createdIn: string
  currentTaskAssignee: string
  currentTaskDueDate: Date
  currentTaskPriority: number
  variables: ProcessVariable[]
  hasIncidents: boolean
  typedValues: any
}

export interface ProcessInstanceActivity {
  activityId: string
  activityName: string
  activityType: string
  activityInstanceId: string
  assigneeId?: any
  assigneeType?: AssigneeCandidateType
  priority: number
  dueDate?: Date
  description?: string
}

type ActionScope = 'User task' | 'Service task' | 'Process instance'
export interface ProcesInstanceActions {
  id: string
  name: string
  scope: ActionScope
}

export interface ProcessInstancesResponse {
  results: ProcessInstance[]
  page: number
  size: number
  total: number
}

export interface ProcessVariable {
  value: any
  key: string
}

export interface ProcessInstanceDetails {
  autoDescriptionOn: boolean
  autoDescription: string
  created: Date
  createdBy: string
}

export interface ProcessInstanceLinkedProcesses {
  processInstanceId: string
  code: string
  processColor: string
  name: string
  assignee: string
  created: Date
  priority: number
  dueDate: Date
  activity: string
  linkType: string
}

export interface SubprocessFilter {
  label: string
  value: string
}

export interface SubprocessResult {
  processInstanceId: string
  code: string
  processColor: string
  name: string
  assignee: string
  created: Date
  priority: number
  createdBy: string
  dueDate: Date
  activity: string
}

export interface SubprocessesResponse {
  filters: SubprocessFilter[]
  results: SubprocessResult[]
}

export interface VariableTab {
  key: string
  label: string
}

export interface VariableField {
  label: string
  key: string
  value: string
  imageUrl?: any
  type: string
}
export interface VariableDataFields {
  fields: VariableField[]
}
export interface VariableData {
  tabKey: string
  data: VariableDataFields[]
}
export interface ProcessInstanceVariables {
  tabs: VariableTab[]
  variables: VariableData[]
}

export interface ProcessInstanceAttachments {
  variableSection: string
  variable: string
  attachmentInfo: {
    id: string
    type: string
    name: string
    size: string
  }
  uploadedBy: string
  date: string
}

export interface ProcessInstanceGallery {
  code: string
  processColor: string
  processName: string
  assigneOrgPosition: string
  assigneeName: string
  attachmentInfo: {
    id: string
    name: string
    type: string
    size: string
    url: string
  }
}

export type ProcessInstanceHistory = {
  activityUser: string
  //activityAssigneeImage: string
  state: string
  activityCreatedAt: Date
  activityType: string
  activityName: string
  activityDuration: string
  activityId: string
  variableChanges: VariableChanges[]
  activityFieldChanges: ActivityChanges[]
}

export type VariableChanges = {
  name: string
  oldValue: any
  newValue: any
}

export type ActivityChanges = {
  name: string
  oldValue: any
  newValue: any
  timestamp: any
}

export type Incident = {
  activityInstanceId: string
  code: string
  message: string
  description: string
  retry: number
  retryTimeout: number
  retriable: boolean
  failedAt: Date
}

export interface ProcessField {
  id: string
  name: string
  itemType: string
  position: number
  scope: string
  isVisible: boolean
  type: string
  processInstanceField
}

export enum AssigneeCandidateType {
  InstanceStarter = 0,
  InstanceStarterNode = 1,
  Group = 2,
  OrganizationNode = 3,
  User = 4,
}
