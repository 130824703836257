import React, { FC, Fragment, useEffect, useState } from 'react'
import { DeleteModal, ImgWrapper, Input, SpinnerIcon } from '@sistemiv/s-components'
import { useParams } from 'react-router-dom'
import { ChevronDownIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useRemoveUserFromOrg } from '../../../../repositories/settings/processes/processes/mutations/remove-user-from-org.repository'
import { imageBase } from '../../../../services/http-common'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import ProcessAccessHeader from './ProcessAccessHeader'
import { useListUsersWithAccess } from '../../../../repositories/settings/processes/processes/list-users-with-access.repository'
import { Listbox, Transition } from '@headlessui/react'
import { useChangeUserAccess } from '../../../../repositories/settings/processes/processes/mutations/change-user-access.repository'
import { useListAllUsersInOrg } from '../../../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { useAddUserInOrg } from '../../../../repositories/settings/processes/processes/mutations/add-user-in-org.repository'
import AddUsersModal from './AddUsersModal'
import toast from 'react-hot-toast'

const accessLevels = [
  { id: 'My', name: 'My' },
  { id: 'OrgNode', name: 'OrgNode' },
  { id: 'All', name: 'All' },
]

const ProcessAccessPage: FC = () => {
  const { org, id } = useParams()
  const [search, setSearch] = useState('')
  const [pendingSearch, setPendingSearch] = useState('')
  //   const [members, setMembers] = useState<any>(null)
  const { data: usersInOrg } = useListAllUsersInOrg()
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)
  const [removingMemberId, setRemovingMemberId] = useState('')
  const { mutate: removeUserFromOrg, isPending } = useRemoveUserFromOrg()
  const { mutate: changeUserAccess } = useChangeUserAccess()
  const { mutate: addUserInOrg } = useAddUserInOrg()
  const { t } = useTranslation()
  const [item, setItem] = useState<any>()
  const { data: acessModalProcessData, isPending: accessModalProcessDataLoading } = useListUsersWithAccess(
    item && !item.id.includes('CategoryItem') ? item.id : '',
  )
  const [items, setItems] = useState(acessModalProcessData)
  console.log(acessModalProcessData)

  useEffect(() => {
    ProcessDefinitionsSettings.list(org!, {
      versions: false,
    }).then((resp) => {
      console.log(resp)
      const item = resp.find((item) => item.id === id)
      setItem(item)
    })
  }, [org, id])

  useEffect(() => {
    setItems(acessModalProcessData)
  }, [acessModalProcessData])

  useEffect(() => {
    if (search) {
      console.log(search)
      const newItems = acessModalProcessData.filter((user) => user?.name?.toLowerCase().includes(search?.toLowerCase()))
      setItems(newItems)
    } else setItems(acessModalProcessData)
  }, [search, acessModalProcessData])

  const handleRemoveAccess = () => {
    if (!org || !id || removingMemberId === '') return
    removeUserFromOrg(
      { itemId: item.id, processAccessId: item.processAccessId, userId: removingMemberId },
      {
        onSuccess: () => {
          setRemoveUserModalOpen(false)
        },
      },
    )
  }

  const handleChangeUserAccess = (value, user) => {
    if (!item) return
    changeUserAccess({
      userId: user.objectId,
      itemId: item.id,
      processAccessId: item.processAccessId,
      accessLevel: value.id,
    })
  }
  const [addMembersOpen, setAddMembersOpen] = useState(false)

  const handleAddAccess = (value, item, accessLevel) => {
    addUserInOrg(
      {
        itemId: item.id,
        processAccessId: item.processAccessId,
        userId: value.objectId,
        accessLevel: accessLevel,
      },
      {
        onSuccess: () => {
          toast.success(t('Settings.addAccessMsg'))
        },
      },
    )
  }

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      setSearch(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch])

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <ProcessAccessHeader onAdMembers={() => setAddMembersOpen(true)} name={item?.name} />
      {accessModalProcessDataLoading ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (
        <div className='p-8 w-full h-full overflow-auto grow'>
          {((acessModalProcessData && acessModalProcessData?.length > 0) || search !== '') && (
            <div className='w-[350px] pb-6'>
              <Input
                id={'search'}
                type={'search'}
                classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100'
                placeholder={t('Common.search') as string}
                value={pendingSearch}
                onChange={(e) => setPendingSearch(e.target.value)}
              />
            </div>
          )}

          {!items?.length && !accessModalProcessDataLoading ? (
            <p className='text-[#5C6375]'>{t('Settings.noAccess')}</p>
          ) : (
            items.map((user, index) => (
              <div
                className='group grid grid-cols-2 w-[60%] gap-y-2 gap-x-[200px] mb-2 hover:bg-sky-50 cursor-pointer px-3'
                key={index}
              >
                <div className='flex items-center gap-x-2 w-[350px]'>
                  <div className='w-7 h-7 rounded-full overflow-hidden flex items-center justify-center bg-slate-400'>
                    <ImgWrapper
                      src={`${imageBase}/${user.objectId}/76`}
                      className='w-full h-full'
                      onErrorComponent={
                        <p className='text-sm text-white leading-[18px]'>
                          {user ? user.name?.charAt(0) : <UserIcon className='w-full h-full text-white' />}
                        </p>
                      }
                    />
                  </div>
                  <p className='text-sm'>{user.name}</p>
                </div>
                <div className='flex justify-end'>
                  <Listbox value={user.accessLevel} onChange={(value) => handleChangeUserAccess(value, user)}>
                    <div className='relative w-fit'>
                      <Listbox.Button className='relative w-full px-3 py-2 cursor-default rounded-md pr-10 text-left border border-gray-300'>
                        {user.accessLevel}
                        <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                          <ChevronDownIcon className='w-5 h-5 text-gray-700 cursor-pointer' aria-hidden='true' />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <Listbox.Options className='absolute z-10 mt-1 min-w-[12rem] rounded-md max-h-60 w-fit overflow-auto bg-white py-3 drop-shadow-lg shadow-xl ring-1 ring-black ring-opacity-5'>
                          {accessLevels
                            .filter((l) => l.id !== user.accessLevel)
                            .map((level) => (
                              <Listbox.Option
                                className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                                key={level.id}
                                value={level}
                              >
                                {level.name}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                  <button
                    onClick={() => {
                      setRemoveUserModalOpen(true)
                      setRemovingMemberId(user.objectId)
                    }}
                    className='ml-auto group-hover:visible invisible'
                  >
                    <XMarkIcon className='w-5 h-5 stroke-gray-500' />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      {addMembersOpen && (
        <AddUsersModal
          usersInOrg={usersInOrg ? usersInOrg.users : []}
          // dataLoading={accessModalProcessDataLoading}
          data={acessModalProcessData}
          item={item}
          onAddAccess={handleAddAccess}
          // onRemoveAccess={handleRemoveAccess}
          // onChangeUserAccess={handleChangeUserAccess}
          open={addMembersOpen}
          setOpen={setAddMembersOpen}
        />
      )}
      {removeUserModalOpen && (
        <DeleteModal
          title={t('Settings.removeAccess')}
          description={t('Settings.removeAccessMsg')}
          open={removeUserModalOpen}
          isLoading={isPending}
          setOpen={setRemoveUserModalOpen}
          onDeleteConfirmed={handleRemoveAccess}
          actionLabel={t('Settings.remove') as string}
        />
      )}
    </div>
  )
}

export default ProcessAccessPage
