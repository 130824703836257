import { Select, Option, SpinnerIcon } from '@sistemiv/s-components'
import Table, { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useProcessIntanceSubprocesses } from '../../repositories/process-instances/process-instance-subprocesses.repository'
import dayjs from 'dayjs'
import { mapPriority } from '../../utils/mappers'
import { imageBase } from '../../services/http-common'
import { useNavigate, useParams } from 'react-router-dom'

const subprocessesHeaders = [
  { accessor: 'code', label: 'Code' },
  { accessor: 'name', label: 'Name' },
  { accessor: 'created', label: 'Created' },
  { accessor: 'priority', label: 'Priority' },
  // { accessor: 'createdBy', label: 'Created by' },
  { accessor: 'dueDate', label: 'Due date' },
  { accessor: 'activity', label: 'Activity' },
  { accessor: 'assignee', label: 'Task assignee' },
  { accessor: 'resolution', label: 'Resolution' },
]

const SubprocessesTab: FC<{ subprocesses: string[]; users: any[] }> = ({ subprocesses, users }) => {
  const { data: processesData, isLoading: loading, isFetching } = useProcessIntanceSubprocesses(subprocesses)
  const { org } = useParams()
  const navigate = useNavigate()
  const [filter, setFilter] = useState('')
  const [tableData, setTableData] = useState<any[]>([])
  const options = subprocesses.map((subp) => {
    return { value: subp, label: subp }
  })

  const getTableData = useCallback(
    (processes: any[]) => {
      const data: TableRow[] = processes?.map((process) => {
        return {
          id: { type: 'value', value: process?.id ?? 'N/A' },
          code: {
            type: 'link',
            value: process?.processInstanceCode ?? '',
            onLinkClicked: () =>
              navigate({ pathname: `/${org}/process-instance/${process.processDefinitionKey}/${process.id}` }),
          },
          name: { type: 'value', value: process?.name ?? 'N/A' },
          created: {
            type: 'value',
            value: process?.created ? dayjs(new Date(process?.created)).format('DD MMM YYYY H:mm') : 'N/A',
          },
          priority: { type: 'priority', value: mapPriority(process?.priority) },
          // createdBy: {
          //   type: 'circled_icon',
          //   tooltip: users?.find((u) => u.objectId === process?.userId)?.name ?? '',
          //   value: users?.find((u) => u.objectId === process?.userId)?.name ?? '',
          //   icon: `${imageBase}/${process?.userId}/76`,
          // },
          dueDate: {
            type: 'value',
            value: process?.dueDate ? dayjs(new Date(process?.dueDate)).format('DD MMM YYYY H:mm') : 'N/A',
          },
          activity: {
            type: 'solid',
            subType: 'activity',
            value: process?.activities?.map((a) => a.activityName) ?? [''],
            solidColor: '#DEE1FC',
          },
          assignee: {
            type: 'circled_icon',
            tooltip:
              process?.activities?.length > 0
                ? process.activities.map((a) => users?.find((u) => u.objectId === a?.assigneeId)?.name ?? '').join()
                : '',
            value:
              process?.activities?.length > 0
                ? process.activities.map((a) => users?.find((u) => u.objectId === a?.assigneeId)?.name ?? '').join()
                : '',
            icon: process.activities.length ? process.activities.map((a) => `${imageBase}/${a?.assigneeId}/76`) : [''],
          },
          resolution: { type: 'border', value: process?.resolution ?? '', borderColor: '#00C595' },
        }
      })
      return data
    },
    [users, navigate, org],
  )

  useEffect(() => {
    if (processesData && processesData.length > 0) {
      if (!filter) setTableData(getTableData(processesData))
      const filteredData = processesData.filter((data) => data.processDefinitionKey === filter)
      filteredData.length > 0 && setTableData([...getTableData(filteredData)])
    }
  }, [processesData, getTableData, filter])

  return (
    <div className='flex flex-col h-full' data-testid='subprocesses-tab'>
      <div className='flex flex-col justify-center items-start mb-4 ml-4'>
        <Select
          value={options.find((o) => o.value === filter)}
          defaultValue={'Show all'}
          onChange={(e) => setFilter(e)}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              <div className='whitespace-nowrap'>{option.label}</div>
            </Option>
          ))}
        </Select>
      </div>
      {loading || isFetching ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (
        <div className='flex flex-col mt-4 overflow-auto'>
          {tableData?.length > 0 && <Table headers={subprocessesHeaders} data={tableData ?? []} />}
        </div>
      )}
    </div>
  )
}

export default SubprocessesTab
