import { Menu, Transition } from '@headlessui/react'
import { SortIcon, SortIconFlipped } from '@sistemiv/s-components'
import React, { FC, Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigationContext } from '../../../context/ResponsiveContextProvider'

type InstanceSorterMobileProps = {
  sortField: string
  setSortField: (value: React.SetStateAction<string>) => void
  options: { id: string; name: string }[]
  order: 'asc' | 'desc'
  setOrder: (value: React.SetStateAction<string>) => void
}
const InstanceSorterMobile: FC<InstanceSorterMobileProps> = ({ sortField, setSortField, options, order, setOrder }) => {
  const { t } = useTranslation()
  const { setHideBottomNavigation } = useNavigationContext()

  const openMobileModal = useRef(false)

  const toggleModal = (open: boolean) => {
    if (openMobileModal.current !== open) {
      setHideBottomNavigation(open)
    }
    openMobileModal.current = open
  }
  return (
    <div className='flex flex-row items-center'>
      <Menu as='div' className='relative z-20'>
        {({ open }) => {
          toggleModal(open)

          return (
            <>
              <Menu.Button>
                <span className='text-gray-600 pl-3 my-3'>
                  {options.find((o) => o.id === sortField)?.name || t('ProcessInstance.filters.sortBy')}
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity duration-80'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-80'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
              </Transition>
              <Transition
                as={Fragment}
                enter={'transition-transform duration-80 ease-out'}
                enterFrom={'transform translate-y-full'}
                enterTo={'transform translate-y-0'}
                leave={'transition-transform duration-80 ease-in'}
                leaveFrom={'transform translate-y-0'}
                leaveTo={'transform translate-y-full'}
              >
                <Menu.Items className='fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg py-4 px-4 gap-y-1 z-20 max-h-[50vh]'>
                  <div className='w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                  <div className='flex flex-col w-full'>
                    <div className='flex flex-row pb-2'></div>
                    <div className='w-full max-h-[calc(50vh-50px)] overflow-y-auto overflow-x-hidden pb-6'>
                      {options.map((option) => (
                        <Menu.Item key={option.id}>
                          {() => (
                            <div
                              onClick={() =>
                                options.find((o) => o.id === sortField) === option
                                  ? setSortField('')
                                  : setSortField(option.id)
                              }
                              className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0 flex items-center justify-between'
                            >
                              <span>{option.name}</span>
                              <span className='ml-5'>
                                {option.id === sortField ? (
                                  order === 'desc' ? (
                                    <SortIconFlipped className='w-4 h-4 fill-gray-400' />
                                  ) : (
                                    <SortIcon className='w-4 h-4 fill-gray-400' />
                                  )
                                ) : null}
                              </span>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )
        }}
      </Menu>
      <span className='ml-5' onClick={() => setOrder && setOrder(order === 'asc' ? 'desc' : 'asc')}>
        {order === 'desc' ? (
          <SortIconFlipped className='w-4 h-4 fill-gray-400' />
        ) : (
          <SortIcon className='w-4 h-4 fill-gray-400' />
        )}
      </span>
    </div>
  )
}

export default InstanceSorterMobile
