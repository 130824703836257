import { useState } from 'react'
import UserService from '../services/UserService'
import { imageBase } from '../services/http-common'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'

const useAssigneeOptions = (org: string | undefined) => {
  const [orgs, setOrgs] = useState<TableField[]>([])
  const [groups, setGroups] = useState<TableField[]>([])
  const [users, setUsers] = useState<TableField[]>([])

  const fetchData = async () => {
    if (!org) return

    const [userRes, groupRes, orgRes] = await Promise.all([
      UserService.listAllUsersInOrg(org),
      UserService.listAllGroups(org),
      UserService.listAllOrgNodes(org),
    ])

    setUsers(
      userRes.users.map((item) => ({
        type: 'circled_value',
        id: item.objectId,
        value: item.name,
        icon: `${imageBase}/${item.objectId}/76`,
        tab: 'Users',
      })),
    )

    setGroups(
      groupRes.results.map((item) => ({
        type: 'b64_value',
        id: item.id,
        value: item.name,
        icon: item.icon,
        tab: 'Groups',
      })),
    )

    setOrgs(
      orgRes.results.map((item) => ({
        type: 'value',
        id: item.id,
        value: item.name,
        tab: 'Org chart',
      })),
    )
  }

  return { users, groups, orgs, fetchData }
}

export default useAssigneeOptions
