import React, { FC, useEffect, useRef, useState } from 'react'
import { useNavigationContext } from '../../../context/ResponsiveContextProvider'
import HeaderFiltersMobile from './HeaderFiltersMobile'
import CustomFiltersMobile from './CustomFiltersMobile'
import { ProcessTableHeaders } from '../table/TableProcess'

type ProcessInstancesFiltersMobileProps = {
  headers: ProcessTableHeaders[]
  onSearch?: (field: string, operation: string, value: any) => void
  filters: string[]
  headerFilters: any
}

const ProcessInstancesFiltersMobile: FC<ProcessInstancesFiltersMobileProps> = ({
  headers,
  onSearch,
  filters,
  headerFilters,
}) => {
  const { setHideBottomNavigation } = useNavigationContext()
  const openMobileModals = useRef({
    Activity: false,
    Priority: false,
    Resolution: false,
    CustomFiltersMobile: false,
  })
  const [mobileModalTrigger, setMobileModalTrigger] = useState(0)

  const toggleModal = React.useCallback((modalName: string, open: boolean) => {
    if (openMobileModals.current[modalName] !== open) {
      openMobileModals.current[modalName] = open
      setMobileModalTrigger((prev) => prev + 1)
    }
  }, [])
  useEffect(() => {
    setHideBottomNavigation &&
      setHideBottomNavigation(
        openMobileModals.current.Activity ||
          openMobileModals.current.Priority ||
          openMobileModals.current.Resolution ||
          openMobileModals.current.CustomFiltersMobile,
      )
  }, [mobileModalTrigger, setHideBottomNavigation])

  return (
    <>
      <HeaderFiltersMobile
        headers={headers}
        onSearch={onSearch}
        filters={filters}
        headerFilters={headerFilters}
        toggleModal={toggleModal}
      />
      <CustomFiltersMobile toggleModal={toggleModal} />
    </>
  )
}

export default ProcessInstancesFiltersMobile
