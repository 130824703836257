import React, { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { ProcessTableHeaders } from '../table/TableProcess'
import { useParams } from 'react-router-dom'
import { imageBase } from '../../../services/http-common'
import UserService from '../../../services/UserService'
import { useProcessInstances } from '../../../repositories'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Combobox, Menu, Transition } from '@headlessui/react'
import { t } from 'i18next'
import { Button, DataField, SideMenuParentArrowIcon, SpinnerIcon } from '@sistemiv/s-components'
import classNames from '../../../classNames'
import AssigneeSearchMobile from './AssigneeSearchMobile'
import { AssigneeCandidateType } from '../../../models/ProcessInstance'

const HeaderSearchFilterMobile: FC<{
  onSearch?(id: string, option: string, value: any): void
  header: ProcessTableHeaders
  filters?: string[]
  value: any
  selectOpen?: boolean
  toggleModal?: (modalName: string, open: boolean) => void
}> = ({ onSearch, header, filters, value, selectOpen, toggleModal }) => {
  const { org } = useParams()
  //postoje 4 mogucnosti filtera, assignee opcija 1
  const [groups, setGroups] = useState<any>(null)
  const [orgNodes, setOrgNodes] = useState<any>(null)
  const [usersInOrg, setUsersInOrg] = useState<any>(null)
  const [assigneeOptions, setAssigneeOptions] = useState<any>([])
  const [isAssigneeLoading, setIsAssigneeLoading] = useState(false)

  useEffect(() => {
    if (header.searchType !== 'assignee') return
    if (!org) return
    const fetchData = async () => {
      setIsAssigneeLoading(true)
      try {
        const [groups, orgNodes, usersInOrg] = await Promise.all([
          UserService.listAllGroups(org),
          UserService.listAllOrgNodes(org),
          UserService.listAllUsersInOrg(org),
        ])
        setGroups(groups)
        setOrgNodes(orgNodes)
        setUsersInOrg(usersInOrg)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsAssigneeLoading(false)
      }
    }

    fetchData()
  }, [org, header.searchType, setIsAssigneeLoading])
  useEffect(() => {
    setAssigneeOptions([
      ...(groups?.results?.map((r) => ({
        type: 'b64_value',
        id: r.id,
        value: r.name,
        icon: r.icon,
        tab: 'Groups',
      })) ?? []),
      ...(orgNodes?.results?.map((r) => ({
        type: 'value',
        id: r.id,
        value: r.name,
        tab: 'Org chart',
      })) ?? []),
      ...(usersInOrg?.users?.map((r) => ({
        type: 'circled_value',
        id: r.objectId,
        value: r.name,
        icon: `${imageBase}/${r.objectId}/76`,
        tab: 'Users',
      })) ?? []),
    ])
  }, [groups, orgNodes, usersInOrg])
  ////
  const [size, setSize] = useState(20)

  const {
    data: optionsRaw,
    isLoading,
    isFetching,
  } = useProcessInstances({
    page: 1,
    size,
    params: {
      select:
        header.scope === 'Activity' && header.processInstanceField.toLowerCase() !== 'activities'
          ? `activities.${header.processInstanceField}`
          : header.scope === 'Variable'
          ? `typedValues.${header.processInstanceField}`
          : `${header.processInstanceField}`,
      filters,
    },
    enabled: header.searchType?.includes('select') && (value?.value?.length > 0 || selectOpen),
  })

  useEffect(() => {
    if (!optionsRaw) return
    if (optionsRaw.total > size) {
      setSize(optionsRaw.total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsRaw])

  const priorityMap = (p) => {
    switch (p) {
      case 0:
        return 'Lowest'
      case 25:
        return 'Low'
      case 50:
        return 'Medium'
      case 75:
        return 'High'
      case 100:
        return 'Highest'
      default:
        return ''
    }
  }

  const options: TableField[] = useMemo(() => {
    return header.scope === 'Activity' && header.processInstanceField !== 'activities'
      ? optionsRaw && optionsRaw.values?.[`activities.${header.processInstanceField}`]
        ? Object.keys(optionsRaw?.values?.[`activities.${header.processInstanceField}`]).map((key) => {
            return {
              id: key,
              value: header.id.toLowerCase().includes('priority')
                ? priorityMap(optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key])
                : optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key],
              solidColor: '#DEE1FC',
              type: header.id.toLowerCase().includes('priority')
                ? 'priority_select'
                : header.processInstanceField.toLowerCase().includes('activities')
                ? 'solid'
                : 'value',
            }
          }) ?? []
        : []
      : optionsRaw && optionsRaw.values?.[header.processInstanceField]
      ? Object.keys(optionsRaw?.values?.[header.processInstanceField]).map((key) => {
          return {
            id: key,
            value: header.id.includes('Priority')
              ? priorityMap(optionsRaw?.values?.[header?.processInstanceField]?.[key])
              : optionsRaw?.values?.[header?.processInstanceField]?.[key],
            solidColor: '#DEE1FC',
            type: header.id.toLowerCase().includes('priority')
              ? 'priority_select'
              : header.processInstanceField.toLowerCase().includes('activities')
              ? 'solid'
              : 'value',
          }
        }) ?? []
      : []
  }, [optionsRaw, header])

  const selectedValue = options.filter((f) => value?.value.includes(f.id)) ?? []
  const onChange = (e) =>
    onSearch?.(
      header.processInstanceField.toLowerCase() === 'activities'
        ? 'activities.activityId'
        : header.scope === 'Activity'
        ? `activities.${header.processInstanceField}`
        : header.scope === 'Variable'
        ? `typedValues.${header.processInstanceField}`
        : header.processInstanceField,
      'in',
      e?.map((v) => v.id),
    )

  return header.searchType === 'select' ? (
    <Menu as='div' className='relative'>
      {({ open }) => {
        toggleModal && toggleModal(header.id, open)
        return (
          <>
            <Menu.Button
              className={classNames(
                'flex flex-row items-center text-sm rounded-full border p-1 px-2 gap-x-1 text-gray-400 !border-gray-400 font-medium',
                selectedValue?.length > 0 ? 'text-white bg-gray-600' : '',
              )}
            >
              <span className='flex gap-x-2 text-sm'>
                {selectedValue?.length > 0 ? (
                  <>
                    <DataField field={selectedValue[0]} className='overflow-hidden whitespace-nowrap max-w-[8rem]' />
                  </>
                ) : (
                  <p className='whitespace-nowrap'>{t(`ProcessInstance.filters.${header.id}`)}</p>
                )}
                {selectedValue?.length > 1 && (
                  <div className='rounded-full text-white bg-sky-400 px-1 py-0'>+{selectedValue?.length - 1}</div>
                )}
              </span>
              <span className=''>
                {selectedValue?.length === 0 ? (
                  <SideMenuParentArrowIcon className='w-3 h-3 fill-gray-400 rotate-90' />
                ) : (
                  <SideMenuParentArrowIcon className='w-3 h-3 fill-white rotate-90' />
                )}
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition-opacity duration-80'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-80'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black opacity-40 z-30' onClick={() => close()} />
            </Transition>
            <Transition
              as={Fragment}
              enter='transition-transform duration-80 ease-out'
              enterFrom='transform translate-y-full'
              enterTo='transform translate-y-0'
              leave='transition-transform duration-80 ease-in'
              leaveFrom='transform translate-y-0'
              leaveTo='transform translate-y-full'
            >
              <Menu.Items className='fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg py-4 px-3 gap-y-1 z-[31] max-h-[50vh]'>
                <div className='w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                <div className='flex flex-col w-full'>
                  <div className='flex flex-row pb-2'>
                    <p className='text-xs font-medium whitespace-nowrap'>{t(`ProcessInstance.filters.${header.id}`)}</p>
                  </div>
                  <div className='w-full max-h-[calc(50vh-50px)] overflow-y-auto overflow-x-hidden pb-6'>
                    <Combobox
                      value={selectedValue.map((o) => o.value)}
                      onChange={(value1) => onChange && onChange(options.filter((f) => value1.includes(f.value)))}
                      multiple
                    >
                      <div className='relative'>
                        {/* Loader */}
                        {(isLoading || isFetching) && (
                          <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
                            <SpinnerIcon className='w-6 h-6 text-sky-500 animate-spin' />
                          </div>
                        )}

                        {/* Options */}
                        <Combobox.Options
                          className={classNames(
                            'max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm',
                            isLoading || isFetching ? 'opacity-50' : 'opacity-100',
                          )}
                          static
                        >
                          <div className='flex justify-between items-center'>
                            <Menu.Item>
                              <Button
                                className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                                onClick={() => onChange && onChange(options)}
                                disabled={selectedValue?.length === options.length}
                              >
                                {t('ProcessInstance.filters.selectAll')}
                              </Button>
                            </Menu.Item>
                            <Menu.Item>
                              <Button
                                className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                                onClick={() => onChange && onChange([])}
                                disabled={selectedValue?.length === 0}
                              >
                                {t('ProcessInstance.filters.clearSelection')}
                              </Button>
                            </Menu.Item>
                          </div>
                          {options.length === 0 ? (
                            <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                              {t('ProcessInstance.filters.nothingFound')}
                            </div>
                          ) : (
                            options?.map((option, index) => (
                              <Combobox.Option
                                key={`option-${index}`}
                                value={option.value}
                                className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                              >
                                {({ selected }) => (
                                  <div className='flex items-center justify-start gap-x-4'>
                                    <input
                                      type='checkbox'
                                      readOnly
                                      checked={selected}
                                      className={classNames(
                                        'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                                      )}
                                    />
                                    <DataField
                                      field={option}
                                      className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                                    />
                                  </div>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </div>
                    </Combobox>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )
      }}
    </Menu>
  ) : header.searchType === 'assignee' ? (
    <AssigneeSearchMobile
      options={assigneeOptions}
      value={assigneeOptions.filter((f) => value?.value.includes(f.id)) ?? []}
      onChange={(v) => {
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? header.processInstanceField === 'assignee'
              ? `activities.assigneeId`
              : `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          v?.map((a) => a.id) ?? [],
        )
        onSearch?.(
          'activities.assigneeType',
          'in',
          v?.[0]?.tab === 'Users'
            ? [AssigneeCandidateType.User, AssigneeCandidateType.InstanceStarter]
            : v?.[0]?.tab === 'Groups'
            ? [AssigneeCandidateType.Group]
            : v?.[0]?.tab === 'Org chart'
            ? [AssigneeCandidateType.OrganizationNode, AssigneeCandidateType.InstanceStarterNode]
            : '',
        )
      }}
      assignee={true}
      loading={isAssigneeLoading}
    />
  ) : (
    <div></div>
  )
}

export default HeaderSearchFilterMobile
