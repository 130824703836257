import React, { FC, useEffect, useRef, useState } from 'react'
import BpmnViewer from 'bpmn-js/dist/bpmn-navigated-viewer.production.min'
import { MinusIcon, PlusIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline'

type DiagramPreviewProps = {
  diagramXML: string
  highlightClass: string
  activities: any[]
  incidents: any[]
  pathActivities: string[]
  selectedId?: string
  onClick?: (id: string) => void
  completed?: boolean
}
const DiagramPreview: FC<DiagramPreviewProps> = ({
  diagramXML,
  highlightClass,
  activities,
  incidents,
  selectedId,
  pathActivities,
  onClick,
  completed,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const viewerInstance = useRef<BpmnViewer>(null)
  const [diagramUpdate, setDiagramUpdate] = useState(false)

  const safeAddMarker = (canvas: any, id: string, attribute: string, remove = false) => {
    try {
      if (remove) {
        canvas.removeMarker(id, attribute)
      } else {
        canvas.addMarker(id, attribute)
      }
      return true
    } catch {
      return false
    }
  }

  useEffect(() => {
    viewerInstance.current = new BpmnViewer({ container: containerRef.current })

    const loadDiagram = async () => {
      try {
        await viewerInstance.current.importXML(diagramXML)
      } catch (err) {
        console.error(err)
        return
      }
      const canvas = viewerInstance.current.get('canvas')
      canvas.zoom('fit-viewport')
      setDiagramUpdate((old) => !old)
    }
    loadDiagram()
    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.destroy()
      }
    }
  }, [diagramXML, onClick])

  useEffect(() => {
    const canvas = viewerInstance.current.get('canvas')
    const eventBus = viewerInstance.current?.get('eventBus')
    eventBus.on('element.click', (event) => {
      onClick?.(event.element.id)
    })
    const registry = viewerInstance.current.get('elementRegistry')

    //color pathactivities and flow
    pathActivities.forEach((act, index) => {
      const pathAct = registry._elements?.[act] && registry._elements?.[act]?.element?.businessObject
      if (pathAct && pathAct.id && pathAct?.$type === 'bpmn:SequenceFlow') {
        canvas.addMarker(pathAct.id, 'path-arrow')
      } else {
        pathAct && pathAct.id && canvas.addMarker(pathAct.id, 'path-activity')
      }

      if (pathAct && pathAct.id && pathAct?.$type === 'bpmn:EventBasedGateway') {
        pathAct.outgoing.forEach((element) => {
          if (element?.targetRef?.id === pathActivities[index + 1]) {
            canvas.addMarker(element?.id, 'path-arrow')
          }
        })
      }
    })

    //color current activities
    activities?.forEach((activity) => {
      const businessObject = registry._elements?.[activity?.activityId]
        ? registry._elements?.[activity?.activityId]?.element?.businessObject
        : registry._elements?.[activity?.activityName]?.element?.businessObject

      safeAddMarker(canvas, businessObject?.id, 'path-activity', true)
      safeAddMarker(canvas, businessObject?.id, highlightClass)

      //color incidents
      if (incidents?.map((i) => i.activityInstanceId).includes(activity.activityInstanceId)) {
        safeAddMarker(canvas, businessObject?.id, 'incident')
        if (businessObject?.$parent && businessObject?.$parent.$type !== 'bpmn:Process') {
          safeAddMarker(canvas, businessObject.$parent.id, 'incident')
        }
      }
    })

    const activity = activities.find((a) => a.activityId === selectedId)
    if (activity) {
      const activeActivities = activities.filter((a) => a.activityId !== selectedId)
      if (activeActivities.length > 0) {
        activeActivities.forEach((act) => {
          safeAddMarker(canvas, act.activityId, 'active', true)
        })
      }
      safeAddMarker(canvas, activity.activityId, 'active')
    }
  }, [activities, highlightClass, incidents, selectedId, diagramUpdate, onClick, pathActivities, completed, diagramXML])

  const handleZoom = (step: number) => {
    if (!viewerInstance) return
    const zoomScroll = viewerInstance.current.get('zoomScroll')
    if (!step) {
      zoomScroll.reset()
      return
    }
    zoomScroll.stepZoom(step)
  }

  return (
    <div className='relative border-gray-100 border-2 h-[calc(100vh-22.5rem)]'>
      <div className='absolute bottom-3 left-3 flex flex-col-reverse gap-1 z-10'>
        <button className='!p-1 border-gray-200 rounded-sm border-2' onClick={() => handleZoom(-1)}>
          <MinusIcon className='w-5 h-5 stroke-gray-500' />
        </button>
        <button className='!p-1 border-gray-200 rounded-sm border-2' onClick={() => handleZoom(1)}>
          <PlusIcon className='w-5 h-5 stroke-gray-500' />
        </button>
        <button className='!p-1 border-gray-200 rounded-sm border-2' onClick={() => handleZoom(0)}>
          <ViewfinderCircleIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </div>
      <div ref={containerRef} className={`px-4 py-3 h-full`} />
    </div>
  )
}

export default React.memo(DiagramPreview)
