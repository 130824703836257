import React, { FC, Fragment, useEffect, useRef } from 'react'
import { useNavigationContext } from '../../../../context/ResponsiveContextProvider'
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../classNames'
import { EmptyState } from '@sistemiv/s-components'

type DetailsTabMobileProps = {
  activities: any[]
  incidents: any[]
  selectedId?: string
  onClick?: (id: string) => void
}

const DetailsTabMobile: FC<DetailsTabMobileProps> = ({ activities, incidents, selectedId, onClick }) => {
  const { t } = useTranslation()
  const { setHideBottomNavigation } = useNavigationContext()
  const openMobileModal = useRef(false)

  useEffect(() => {
    if (activities.length > 0) {
      onClick?.(activities[0].activityId)
    }
  }, [activities, onClick])

  const toggleModal = (open: boolean) => {
    if (openMobileModal.current !== open) {
      setHideBottomNavigation((prev) => prev || open)
    }
  }

  return (
    <div className='flex flex-col gap-y-3'>
      {activities?.length > 0 ? (
        <Menu as='div' className='relative z-20'>
          {({ open }) => {
            toggleModal(open)

            return (
              <>
                <Menu.Button
                  className={
                    'w-full relative flex flex-row items-center border bg-white p-1 rounded-md justify-between'
                  }
                  disabled={activities.length === 1}
                >
                  <div
                    className={classNames(
                      'rounded flex items-start p-0.5 px-2 min-w-fit',
                      incidents
                        ?.map((i) => i.activityInstanceId)
                        .includes(activities.find((a) => a.activityId === selectedId)?.activityInstanceId)
                        ? 'bg-pink-50'
                        : 'bg-[#DEE1FC]',
                    )}
                  >
                    {activities.find((a) => a.activityId === selectedId)?.activityName}
                  </div>
                  {activities.length > 1 && <ChevronDownIcon className='w-5 h-5 stroke-2 text-gray-700 mr-1' />}
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition-opacity duration-80'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='transition-opacity duration-80'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
                </Transition>
                <Transition
                  as={Fragment}
                  enter={'transition-transform duration-80 ease-out'}
                  enterFrom={'transform translate-y-full'}
                  enterTo={'transform translate-y-0'}
                  leave={'transition-transform duration-80 ease-in'}
                  leaveFrom={'transform translate-y-0'}
                  leaveTo={'transform translate-y-full'}
                >
                  <Menu.Items className='fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg py-4 px-3 gap-y-1 z-20 max-h-[50vh]'>
                    <div className='w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                    <div className='flex flex-col w-full'>
                      <div className='flex flex-row pb-2'>
                        <p className='text-xs font-medium whitespace-nowrap'>
                          {t(`ProcessInstance.tabs.currentActivities`)}
                        </p>
                      </div>
                      <div className='w-full max-h-[calc(50vh-50px)] overflow-y-auto overflow-x-hidden pb-6'>
                        {activities.map((activity) => (
                          <Menu.Item key={activity.activityId}>
                            <div className='w-full py-2' onClick={() => onClick?.(activity.activityId)}>
                              <span className='mr-5'>
                                <span className='absolute'>
                                  {activity.activityId === selectedId && (
                                    <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                  )}
                                </span>
                              </span>
                              <span
                                className={classNames(
                                  'rounded p-0.5 px-2 max-w-fit ml-2',
                                  incidents?.map((i) => i.activityInstanceId).includes(activity.activityInstanceId)
                                    ? 'bg-pink-50'
                                    : 'bg-[#DEE1FC]',
                                )}
                              >
                                {activity.activityName}
                              </span>
                            </div>
                          </Menu.Item>
                        ))}
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )
          }}
        </Menu>
      ) : (
        <EmptyState emptyText={t('ProcessInstance.noCurrentActivities')} />
      )}
    </div>
  )
}

export default DetailsTabMobile
